html {
  --color-base: black;
  --color-main: #1f415e;
  --color-primary: #9aca3c;
  --color-secondary: #5c5c5c;
  --color-sub-1: #019adf;
  --color-sub-2: #00be90;
  --color-sub-3: #64ccc9;
  --color-sub-4: #cc9064;
  --color-sub-5: #cc6464;
  --transition: all ease 0.5s;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    margin-bottom: 10px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
}

.mb-60 {
  margin-bottom: 60px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.page {
  .upload-content {
    p {
      font-size: 16px;
      color: black;
      margin: 0 0 16px 0;
      line-height: 1.6;
      &:last-child {
        margin: 0;
      }
    }

    img,
    figure {
      display: block;
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.aspectRatio {
  position: relative;

  &.aspectRatio--100 {
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &.aspectRatio--16-9 {
    &:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }
  }

  &.aspectRatio--5-1 {
    &:before {
      content: "";
      display: block;
      padding-top: 20%;
    }
  }

  &.aspectRatio--4-1 {
    &:before {
      content: "";
      display: block;
    padding-top: 40%;
    @media (max-width: 768px) {
      padding-top: 150%;
    }
    }
  }

  &.aspectRatio--3-4 {
    &:before {
      content: "";
      display: block;
      padding-top: 80%;
    }
  }

  *.aspectRatio-content {}

  &.aspectRatio--verticle {
    &:before {
      content: "";
      display: block;
      padding-top: 200%;
    }
  }
}

.content-w {
  width: 100%;
  max-width: 2048px;
  margin: 0 auto;

  &.size-lg {
    width: 90%;
    max-width: 1600px;

    @media (max-width: 1440px) {
      width: 100%;
      padding: 0 20px;
    }
  }

  &.size-md {
    width: 80%;
    max-width: 1200px;

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 20px;
    }
  }

  &.size-sm {
    width: 70%;
    max-width: 960px;

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 20px;
    }
  }
}

.img {
  img {
    width: 100%;
    display: block;
  }
}

.container {
  max-width: 2048px;
  margin: auto;
  overflow: hidden;

  .page-content {
    padding: 117px 0 0px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    @media (max-width: 1023px) {
      padding: 80px 0 0;
    }
  }
}

.page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  z-index: 22;
  transition: all ease .5s;

  @media (max-width: 1023px) {
    left: -100%;

    &.active {
      left: 0;
    }
  }
}

.menu-mb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  z-index: 22;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  display: none;
  background-color: white;

  @media (max-width: 1023px) {
    display: flex;
  }

  .icon-menu {
    width: 60px;
  }
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.menu {
  height: 100%;
  transition: all ease 0.5s;

  &.left {
    top: 0;
    left: -80%;

    &.active {
      left: 0%;
    }
  }

  &.right {
    top: 0;
    right: -80%;

    &.active {
      right: 0%;
    }
  }

  &.top {
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &.active {
      top: 0;
    }
  }
}

.MuiPagination-root {
  ul {
    justify-content: center;
  }

  li {
    margin: 0 5px;

    .Mui-selected {
      background-color: var(--color-primary);
      color: var(--color-main);
      font-size: 14px;
      font-weight: 600;
      color: white;

    }

    @media (max-width: 1023px) {
      width: inherit;
    }

    button {
      color: var(--color-main);
      font-weight: 500;
    }
  }
}