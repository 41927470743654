.related-post {
  label {
    font-size: 32px;
    color: var(--color-main);
    margin-bottom: 40px;
    font-weight: 600;
    display: block;
    font-family: 'Gentium Book Plus';
    @media (max-width: 1600px) {
      font-size: 28px;
    }
    @media (max-width: 1023px) {
      font-size: 24px;
    }
  }
  .list {
    .swiper {
      padding-top: 0;
      margin-top: 0;
      .swiper-button-next, .swiper-button-prev {
        display: none;
      }
    }
  }
}
