.contact-form {
    form {
        .form-groups {
            .form-groups-w{
                input {
                    padding: 0 14px;
                }
            }
        }
        .custom-btn {
            width: 100%;
            padding: 10px 16px;
            border-radius: 5px;
            color: white;
            background-color: var(--color-primary);
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            border: 0;
        }
    }
}