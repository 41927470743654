.logo {
    width: 120px;
    &:hover {
        cursor: pointer;
    }
    @media (max-width: 1280px) {
        width: 100px;
    }
    @media (max-width: 1023px) {
        width: 80px;
    }
}