.approach {
    &.active {
        .approach-wrap {
            .item {
                .item-info {
                    border-radius: 20px;
                }

                .item-thumb {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                }
            }
        }
    }

    &-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            width: calc(50% - 10px);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 100%;

            @media (max-width: 1280px) {
                width: calc(50% - 8px);
            }

            &:nth-child(1) {
                order: 1;

                .item-info {
                    @media (max-width: 1023px) {
                        margin-bottom: 16px;
                    }

                    &::before {
                        border-left: 40px solid #9aca3c;

                        @media (max-width: 1280px) {
                            border-left: 30px solid #9aca3c;
                        }
                    }

                    &::after {
                        bottom: 0;
                        transform: translateY(100%);
                        border-radius: 0 0 50px 50px;
                        background-color: #9aca3c;

                        @media (max-width: 1023px) {
                            bottom: 50%;
                            transform: translateY(50%) rotate(-90deg);
                            left: 100%;
                        }
                    }
                }
            }

            &:nth-child(3) {
                order: 2;

                @media (max-width: 1023px) {
                    order: 3;
                }

                .item-info {
                    @media (max-width: 1023px) {
                        margin-bottom: 16px;
                    }

                    &::before {
                        border-left: 40px solid #019adf;

                        @media (max-width: 1280px) {
                            border-left: 30px solid #019adf;
                        }
                    }

                    &::after {
                        bottom: 0;
                        transform: translateY(100%);
                        border-radius: 0 0 50px 50px;
                        background-color: #019adf;

                        @media (max-width: 1023px) {
                            bottom: 50%;
                            transform: translateY(50%) rotate(-90deg);
                            left: 100%;
                        }
                    }
                }
            }

            &:nth-child(2) {
                order: 3;
                z-index: 3;

                @media (max-width: 1023px) {
                    order: 2;
                }

                .item-info {
                    order: 2;

                    &::before {
                        border-left: 40px solid #00be90;

                        @media (max-width: 1280px) {
                            border-left: 30px solid #00be90;
                        }
                    }

                    &::after {
                        top: 0;
                        transform: translateY(-100%);
                        border-radius: 50px 50px 0 0;
                        background-color: #00be90;

                        @media (max-width: 1023px) {
                            top: 50%;
                            transform: translateY(-50%) rotate(-90deg);
                            left: -5%;
                        }
                    }
                }

                .item-thumb {
                    order: 1;

                    @media (max-width: 1023px) {
                        margin-bottom: 16px;
                    }
                }
            }

            &:nth-child(4) {
                order: 4;
                z-index: 1;

                .item-info {
                    order: 2;

                    &::before {
                        display: none;
                    }

                    &::after {
                        top: 1px;
                        transform: translateY(-100%);
                        border-radius: 50px 50px 0 0;
                        background-color: var(--color-main);

                        @media (max-width: 1023px) {
                            top: 50%;
                            transform: translateY(-50%) rotate(-90deg);
                            left: -5%;
                        }
                    }
                }

                .item-thumb {
                    order: 1;

                    @media (max-width: 1023px) {
                        margin-bottom: 16px;
                    }
                }
            }

            &-info {
                width: calc(50% - 12px);
                padding: 24px;
                position: relative;

                @media (max-width: 1600px) {
                    padding: 20px;
                }

                @media (max-width: 1280px) {
                    width: calc(50% - 8px);
                }

                @media (max-width: 1023px) {
                    width: 100%;
                    padding: 16px;
                }

                &::before {
                    width: 0px;
                    height: 0px;
                    display: block;
                    content: "";
                    border-top: 40px solid transparent;
                    border-bottom: 40px solid transparent;
                    border-right: 40px solid transparent;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(100%, 25%);
                    transition: all ease 0.5s 1s;
                    z-index: 2;

                    @media (max-width: 1280px) {
                        border-top: 30px solid transparent;
                        border-bottom: 30px solid transparent;
                        border-right: 30px solid transparent;
                    }

                    @media (max-width: 1023px) {
                        border-top: 30px solid transparent;
                        border-bottom: 30px solid transparent;
                        border-right: 30px solid transparent;
                        bottom: 0;
                        top: inherit;
                        right: 50%;
                        transform: translate(50%, 99%) rotate(90deg);
                    }
                }

                &::after {
                    width: 10px;
                    height: 20px;
                    display: block;
                    content: "";
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    z-index: 2;
                    transition: all ease 0.5s 1.2s;
                }

                .item-info-wrap {
                    position: relative;
                    z-index: 2;

                    .inner {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                    }
                }

                &-head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .icon {
                        width: 50px;

                        @media (max-width: 1600px) {
                            width: 40px;
                        }

                        @media (max-width: 1023px) {
                            width: 35px;
                        }
                    }

                    .orders {
                        span {
                            font-size: 32px;
                            color: white;
                            font-weight: 900;
                            display: inline-block;
                            border-bottom: 4px solid white;

                            @media (max-width: 1600px) {
                                font-size: 30px;
                            }

                            @media (max-width: 1023px) {
                                font-size: 22px;
                            }
                        }
                    }
                }

                &-bottom {
                    min-height: 82px;

                    @media (max-width: 1023px) {
                        min-height: 59px;
                    }
                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        color: white;
                        margin-bottom: 4px;
                        text-transform: uppercase;

                        @media (max-width: 1600px) {
                            font-size: 22px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 16px;
                        }
                    }

                    .description {
                        font-size: 18px;
                        color: white;
                        font-weight: 400;

                        @media (max-width: 1600px) {
                            font-size: 16px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 13px;
                        }
                    }
                }
            }

            &-thumb {
                width: calc(50% - 12px);
                clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
                transition: all ease 0.5s .8s;
                border-radius: 20px;
                overflow: hidden;
                @media (max-width: 1280px) {
                    width: calc(50% - 8px);
                }

                @media (max-width: 1023px) {
                    width: 100%;
                }

                span {
                    display: block;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}
