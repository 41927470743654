.block-title {
  &.center {
    text-align: center;
  }

  &.size-lg {
    label {
      font-size: 20px;

      @media (max-width: 1600px) {
        font-size: 18px;
      }

      @media (max-width: 1023px) {
        font-size: 14px;
      }
    }

    h4 {
      font-size: 65px;

      @media (max-width: 1600px) {
      font-size: 60px;
      }
      @media (max-width: 1360px) {
        font-size: 55px;
        }

      @media (max-width: 1023px) {
        font-size: 36px;
      }
    }
  }

  &.isbg {

    h4,
    label {
      color: white;
    }

  }

  label {
    font-size: 18px;
    color: var(--color-main);
    opacity: 0.5;
    font-weight: 600;
    display: block;
    line-height: 1.7;

    @media (max-width: 1360px) {
      font-size: 16px;
    }

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }

  h4 {
    font-size: 35px;
    color: var(--color-main);
    font-weight: 600;
    font-family: 'Gentium Book Plus';
    margin: 0;
    @media (max-width: 1600px) {
      font-size: 32px;
    }

    @media (max-width: 1023px) {
      font-size: 24px;
    }

  }

  .highlight {
    color: var(--color-primary);
  }
}