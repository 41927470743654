

.member {
    padding: 5px;
    border-radius: 180px;
    overflow: hidden;
    position: relative;
    display: flex;
    text-decoration: none;
    color: inherit;
    &::before {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: "";
        transform: translate(-50%, -50%);
        background-image: linear-gradient(white 50%, #9aca3c 30%);
        animation: rotate ease 70s infinite;
    }
    .identify {
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        height: 100%;
        background-color: white;
        position: relative;
        padding: 16px;
        border-radius: 180px;
        width: 100%;
       
        .avata {
            width: 100%;
            margin: auto;
            position: relative;

            span {
                display: block;
                background-position: center center;
                background-repeat: no-repeat;
                border-radius: 100%;
                background-size: 100%;
                position: relative;
                width: 100%;
                z-index: 2;
            }
        }
        .meta {
            text-align: center;
            padding: 40px 20px;
            position: relative;
            z-index: 2;
            @media (max-width: 1023px) {
                padding: 20px 0;
            }
            .name {
                font-size: 20px;
                font-weight: bold;
                color: var(--color-main);
                @media (max-width: 1360px) {
                    font-size: 18px;
                }
                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
            .role {
                font-size: 16px;
                font-weight: 400;
                color: black;
                @media (max-width: 1360px) {
                    font-size: 14px;
                }
                @media (max-width: 1023px) {
                    font-size: 16px;
                }
            }
        }
    }
    .carreer {
        ul {
            margin: 0;
            padding: 0;

            li {
                font-size: 16px;
                color: white;
                margin: 0 0 20px 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    50% {
        transform: translate(-50%, -50%) rotate(720deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(0);
    }
}
