.news-event-detail {
    .head {
        .meta {
            display: flex;
            flex-wrap: wrap;
            .post-info {
                display: flex;
                align-items: center;
                margin-right: 60px;
                @media (max-width: 1023px) {
                    width: 100%;
                    margin-bottom: 16px;
                    margin-right: 0;
                }
                .icon {
                    width: 20px;
                    margin-right: 10px;
                }
                .time {
                    font-size: 14px;
                    color: #3e3e3e;
                    font-weight: 500;
                    span {
                        color: #808080;
                        font-weight: 400;
                        padding-left: 10px;
                        display: inline-block;
                    }
                }
            }
            .share {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #3e3e3e;
                font-weight: 500;
                .social {
                    display: flex;
                    padding-left: 12px;

                    .icon , button {
                        width: 24px;
                        margin-right: 12px;
                        transition: all ease 0.5s;
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
    .upload-content {
        p {
            &:first-child {
                font-weight: bold;
                color: black;
            }
        }
    }
}
