.page-title {
    font-size: 32px;
    font-weight: bold;
    color: var(--color-main);
    margin-bottom: 20px;
    font-family: 'Gentium Book Plus';
    @media (max-width: 1600px) {
        font-size: 28px;
    }
    @media (max-width: 1023px) {
        font-size: 24px;
    }
}