.nav-bar-top {
  background-color: white;
  padding: 12px 0;
  position: relative;
  z-index: 2;

  // @media (max-width: 1023px) {
  //   display: none;
  // }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .block-left {
      .contact-information {
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;

          li {
            list-style-type: none;
            margin-right: 30px;
            @media (max-width: 1023px) {
              &:nth-child(1),  &:nth-child(2) {
                display: none;
              }
            }
          }

          .item {
            text-decoration: none;
            display: flex;
            align-items: center;

            &:hover {
              span {
                color: var(--color-main);
                text-decoration: underline;
              }
            }

            .ic {
              width: 16px;
              margin-right: 10px;
            }

            span,
            label {
              font-size: 14px;
              color: var(--color-secondary);
              transition: all ease 0.5s;
            }
          }

          .select-language {
            display: flex;
            align-items: center;

            .block-select {
              margin-left: 10px;
              position: relative;
              border: 1px solid var(--color-main);
              border-radius: 5px;
              transition: all ease 0.5s;

              &:hover,
              &:focus {
                border: 1px solid var(--color-main);
              }

              &::before {
                width: 0;
                height: 0;
                border-top: 7px solid var(--color-main);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 3px solid transparent;
                position: absolute;
                top: 57%;
                right: 12px;
                transform: translateY(-50%);
                display: block;
                content: "";
                z-index: 0;
              }

              select {
                outline: none;
                font-size: 13px;
                font-weight: 500;
                color: var(--color-main);
                padding: 4px 32px 4px 12px;
                appearance: none;
                background-color: transparent;
                border: none;
                border-radius: none;
                position: relative;
                z-index: 1;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .block-right {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @media (max-width: 1023px) {
        display: none;
      }
      .social {
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;

          li {
            list-style-type: none;
            margin-right: 30px;

            .item {
              .ic {
                width: 16px;
                margin-right: 10px;
                position: relative;

                &:hover,
                &:focus {
                  &::before {
                    opacity: 1;
                    transform: translate(-50%, -50%) scale(1);
                  }
                }

                &::before {
                  display: block;
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%) scale(0.5);
                  width: calc(100% + 20px);
                  height: calc(100% + 20px);
                  border-radius: 5px;
                  border: 0.5px solid var(--color-secondary);
                  opacity: 0;
                  transition: all ease 0.5s;
                }
              }
            }
          }
        }
      }
    }
  }
}