.search {
    display: flex;
    justify-content: flex-end;
    position: relative;
    @media (max-width: 1023px) {
      width: 100%;
      justify-content: center;
    }
    .box-search {
      position: absolute;
      top: 100%;
      right: -20px;
      width: 100%;
      min-width: 200px;
      background-color: #0c2a43;
      border-radius: 10px;
      overflow: hidden;
      opacity: 0;
      transition: all ease 0.5s;
      z-index: 22;
      @media (max-width: 1023px) {
        position: static;
        opacity: 1;
      }
      &.active {
        right: 0;
        opacity: 1;
      }
      .wrapper {
        position: relative;
        .ic {
          width: 20px;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          img {
            &:hover {
              cursor: pointer;
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
      .ic {
        margin-right: 0 !important;
      }
      form {
        padding: 0;
        input {
          height: 40px;
          background-color: transparent;
          border: none;
          outline: 0;
          padding: 0 20px 0 20px;
          font-size: 14px;
          color: white;
          appearance: none;
          width: 100%;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: white;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: white;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: white;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: white;
          }
        }
      }
    }
    .label-search {
      @media(max-width: 1023px) {
        width: 100%;
      }
      .label-search-wrap {
        align-items: center;
        padding: 10px;
        display: inline-flex;
        @media (max-width: 1023px) {
          display: none;
        }
        &:hover {
          cursor: pointer;
          img {
            transform: scale(1.1);
          }
          label {
            cursor: pointer;
            color: var(--color-primary);
          }
        }
      }

      .ic {
        width: 16px;
        margin-right: 10px;
        @media (max-width: 1023px) {
          margin-right: 0;
        }
        img {
          transition: all ease 0.5s;
        }
      }
      label {
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        transition: all ease 0.5s;
        @media (max-width: 1360px) {
          display: none;
        }
      }
    }
  }