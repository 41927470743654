.contact {
    .content {
        .wrap {
            display: flex;
            flex-wrap: wrap;
            .bl {
                width: 50%;
                @media (max-width: 1023px){
                    width: 100%;
                } 
                .block-label {
                    font-size: 18px;
                    text-transform: uppercase;
                    color: var(--color-main);
                    margin-bottom: 20px;
                    display: inline-block;
                    font-weight: bold;
                    padding-bottom: 10px;
                    border-bottom: 2px solid var(--color-primary);
                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }
                }
                &.title {
                    width: 100%;
                    .block-title   {
                        width: 70%;
                        margin: 0 auto 40px ;
                        position: relative;
                        padding-bottom: 40px;
                        @media (max-width: 1023px) {
                            padding-bottom: 20px;
                            width: 100%;
                            margin: 0 auto 20px ;
                        }
                        &::before {
                            width: 200px;
                            height: 4px;
                            display: block;
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            background-color: var(--color-primary);
                        }
                        h4 {
                            text-align: center;
                        }
                    }
                }
                &.form {
                    padding-right: 40px;
                    @media (max-width: 1600px) {
                        padding-right: 20px;
                    }
                    @media (max-width: 1023px) {
                        padding-right: 0;
                        margin-bottom: 40px;
                    }

                }
                &.map {
                    padding-left: 40px;
                    @media (max-width: 1600px) {
                        padding-left: 20px;
                    }
                    @media (max-width: 1023px) {
                        padding-left: 0;
                    }
                    .meta {
                        padding-top: 29px;
                        &-item {
                            cursor: pointer;
                            text-decoration: none;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            border-radius: 10px;
                            transition: all ease 0.5s;
                            border: 1px solid #e1e1e1;
                            margin: 0 0 20px 0;
                            background-color: var(--color-main);
                            overflow: hidden;
                            height: 100px;
                            align-items: center;
                            .icon {
                                width: 80px;
                                text-align: center;
                                .img {
                                    width: 40%;
                                    margin: auto;
                                }
                            }
                            .info {
                                width: calc(100% - 80px);
                                border-left: 1px solid #e1e1e1;
                                padding: 20px;
                                height: 100%;
                                background-color: white;
                                display: flex;
                                align-items: center;
                                label {
                                    font-size: 24px;
                                    font-weight: 600;
                                    color: var(--color-main);
                                    display: block;
                                    padding-bottom: 16px;
                                    margin-bottom: 16px;
                                    position: relative;
                                    @media (max-width: 1600px) {
                                        font-size: 18px;
                                        padding-bottom: 12px;
                                        margin-bottom: 12px;
                                    }
                                    @media (max-width: 1023px) {
                                        font-size: 14px;
                                    }
                                }
                                .value {
                                    font-size: 16px;
                                    color: var(--color-main);
                                    @media (max-width: 1600px) {
                                        font-size: 14px;
                                    }
                                    @media (max-width: 1023px) {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
