.nav-bar {
  background-color: var(--color-main);
  position: relative;

  @media (max-width: 1023px) {
    height: 100vh;
    padding: 20px 0 80px;
    overflow-y: auto;
  }

  .content-w {
    @media (max-width: 1023px) {
      padding: 0;
    }
  }

  .login {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 50px;
    overflow: hidden;

    @media (max-width: 1023px) {
      position: static;
      height: auto;
      padding: 0 20px;
      justify-content: center;
    }

    .wrap {
      position: relative;
      background-color: white;
      height: 100%;
      padding: 0 30px;

      @media (max-width: 1600px) {
        padding: 0 20px 0 0;
      }

      @media (max-width: 1023px) {
        background-color: transparent;
        background: 0;
        padding: 0;
        width: 100%;

        .button,
        .txt,
        div {
          width: 100%;
        }

        .txt {
          justify-content: center;
        }
      }

      &::before {
        width: 0;
        height: 0;
        border-bottom: 100px solid white;
        border-left: 50px solid transparent;
        position: absolute;
        bottom: 50%;
        left: 0;
        display: block;
        content: "";
        transform: translate(-100%, 50%);
        z-index: 2;

        @media (max-width: 1023px) {
          display: none;
        }
      }
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px 16px 0;
    flex-wrap: wrap;

    @media (max-width: 1023px) {
      padding: 0;
    }

    .block {
      width: 15%;

      @media (max-width: 1023px) {
        width: 100%;
        display: flex;
      }

      &.block-left {
        .close-menu {
          display: none;
        }

        @media (max-width: 1023px) {
          margin-bottom: 40px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .close-menu {
            display: block;
            width: 24px;
          }
        }
      }

      &.block-center {
        width: 70%;

        @media (max-width: 1023px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      &.block-right {
        padding-right: 5%;

        @media (max-width: 1360px) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 40px;
        }

        @media (max-width: 1280px) {
          padding-left: 0;
          padding-right: 8%;
        }

        @media (max-width: 1023px) {
          width: 100%;
          padding: 0 20px;

          @media (max-width: 1023px) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .icon-menu {
    width: 36px;
    cursor: pointer;

    img {
      transition: all ease 0.5s;
    }
  }
}