@import "fonts";
@import "setup";
@import "reset";

@font-face {
  font-family: "Gentium Book Plus";
  src: url("../fonts/GentiumBookPlus.eot");
  src: url("../fonts/GentiumBookPlus.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GentiumBookPlus.woff") format("woff"),
    url("../fonts/GentiumBookPlus.ttf") format("truetype"),
    url("../fonts/GentiumBookPlus.svg#GentiumBookPlus") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gentium Book Plus";
  src: url("../fonts/GentiumBookPlus-BoldItalic.eot");
  src: url("../fonts/GentiumBookPlus-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/GentiumBookPlus-BoldItalic.woff") format("woff"),
    url("../fonts/GentiumBookPlus-BoldItalic.ttf") format("truetype"),
    url("../fonts/GentiumBookPlus-BoldItalic.svg#GentiumBookPlus-BoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gentium Book Plus";
  src: url("../fonts/GentiumBookPlus-Italic.eot");
  src: url("../fonts/GentiumBookPlus-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/GentiumBookPlus-Italic.woff") format("woff"),
    url("../fonts/GentiumBookPlus-Italic.ttf") format("truetype"),
    url("../fonts/GentiumBookPlus-Italic.svg#GentiumBookPlus-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gentium Book Plus";
  src: url("../fonts/GentiumBookPlus-Bold.eot");
  src: url("../fonts/GentiumBookPlus-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/GentiumBookPlus-Bold.woff") format("woff"),
    url("../fonts/GentiumBookPlus-Bold.ttf") format("truetype"),
    url("../fonts/GentiumBookPlus-Bold.svg#GentiumBookPlus-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;

  &.show {
    overflow: auto;
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }
}

.swiper {
  .swiper-button-next,
  .swiper-button-prev {
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    transition: var(--transition);
    transform-origin: top;

    @media (max-width: 1600px) {
      width: 50px;
      height: 50px;
    }

    @media (max-width: 1023px) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      transform: scale(1.2) translateY(-50%);
    }

    &::after {
      display: none;
    }
  }

  .swiper-button-next {
    background: url("../images/ArrowLeftCircle.png") center no-repeat;
    background-size: 100%;
    right: 40px;
    margin-top: 0;

    @media (max-width: 1023px) {
      right: 20px;
      display: none;
    }
  }

  .swiper-button-prev {
    background: url("../images/ArrowRightCircle.png") center no-repeat;
    background-size: 100%;
    left: 40px;
    margin-top: 0;

    @media (max-width: 1023px) {
      left: 20px;
      display: none;
    }
  }

  .swiper-pagination {
    &-bullet {
      background-color: #d9d9d9;
      width: 10px;
      height: 10px;
      opacity: 1;
      margin: 0 10px !important;

      @media (max-width: 1023px) {
        width: 8px;
        height: 8px;
        margin: 0 5px !important;
      }

      &.swiper-pagination-bullet-active {
        background-color: var(--color-main);
      }
    }
  }
}

.badge-list {
  display: flex;
  flex-wrap: wrap;

  &.center {
    justify-content: center;

    .item {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .item {
    margin-right: 30px;
    margin: 8px 30px 8px 0;
  }
}
.toHomepage {
  background-color: var(--color-main);
  position: fixed;
  top: 50%;
  right: 0;
  transform: rotate(90deg) translate(0px, -100%);
  transform-origin: right;
  z-index: 22;
  transition: all ease 0.5s;

  &.active {
    transform: rotate(90deg) translate(0px, 50%);
  }
  &:hover {
    opacity: 0.8;
  }
  a {
    padding: 10px 20px;
    color: white;
    display: inline-block;
    text-decoration: none;
    @media (max-width: 1023px) {
      font-size: 14px;
      padding: 8px 20px;
    }
  }
}
.scroll-to {
  bottom: 24px;
  position: fixed;
  right: 75px;
  width: 45px;
  z-index: 3;
  &:hover {
    cursor: pointer;
  }
}

.pointer-none {
  pointer-events: none !important;
}

form {
  .form-groups {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .error {
      font-size: 13px;
      padding-top: 8px;
      color: red;
      display: none;
      text-align: left !important;
    }

    .form-groups-w {
      label {
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 8px;
        color: #2c2c2c;

        span {
          color: red;
        }
      }

      input,
      select,
      textarea {
        width: 100%;
        height: 44px;
        border: 1px solid #e0e0e0;
        outline: none;
        font-size: 14px;
        color: var(--color-main);
        padding: 0 14px;
        box-sizing: border-box;
        transition: all ease 0.5s;
        border-radius: 4px;
        font-weight: 600;
        font-family: "Inter", sans-serif;

        &:hover,
        &:focus {
          border: 1px solid var(--color-main);
        }

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(#1f415e, 0.2);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(#1f415e, 0.2);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(#1f415e, 0.2);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(#1f415e, 0.2);
        }
      }

      .button {
        width: 100%;

        .txt {
          width: 100%;
          justify-content: center;
        }
      }

      .select-wrap {
        position: relative;

        &::before {
          width: 16px;
          height: 16px;
          display: block;
          content: "";
          background: url("../../assets/images/arrow-down-grey.png") center
            no-repeat;
          background-size: 100%;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          z-index: 2;
        }

        select {
          padding: 0 32px 0 40px;
          appearance: none;
          width: 100%;
          background-color: transparent;
        }
      }

      textarea {
        padding: 12px 14px;
        height: 200px;
        width: 100%;
        resize: none;
      }
    }
  }

  .block-action {
    &.two-button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        width: calc(50% - 8px);

        button {
          display: block;
          width: 100%;
        }
      }
    }

    button {
      display: inline-flex;
      padding: 12px 16px;
      border-radius: 5px;
      color: white;
      background-color: var(--color-main);
      font-size: 16px;
      font-weight: 600;
      transition: all ease 0.5s;
      text-decoration: none;
      align-items: center;
      border: none;

      @media (max-width: 1600px) {
        font-size: 14px;
      }

      @media (max-width: 1023px) {
        font-size: 16px;
      }

      &.grey {
        background-color: var(--color-secondary);

        &:hover {
          opacity: 0.8;
          background-color: var(--color-secondary);
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.list-card {
  .wrap {
    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: calc(33% - 40px);
        margin: 0 20px 40px;

        @media (max-width: 1023px) {
          width: 100%;
          margin: 0 0 40px 0;
        }
      }
    }
  }
}

.ani {
  opacity: 0;
  transition: all ease 0.8s 0.1s;
  &.fadeInUp {
    transform: translateY(20px);
  }

  &.fadeInLeft {
    transform: translateX(-40px);
  }

  &.fadeInRight {
    transform: translateX(40px);
  }
}

.is-inview {
  opacity: 1;

  &.fadeInUp {
    transform: translateY(0px);
  }

  &.fadeInLeft {
    transform: translateX(0px);
  }

  &.fadeInRight {
    transform: translateX(0px);
  }

  &.scale {
    animation: scale forwards 1s ease;
  }
}

@keyframes scale {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
* {
  ::selection {
    color: white;
    background: var(--color-main);
  }
}
// #loading {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   background-color: var(--color-main);
//   top: 0;
//   left: 0;
//   z-index: 22222;
//   transition: all ease 0.5s;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .logo {
//     position: relative;
//     overflow: hidden;
//     &::before {
//       width: 200%;
//       height: 200%;
//       display: block;
//       content: "";
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: skew(-15deg) translate(-150%, -50%);
//       background-color: rgba(#1f415e, 0.5);
//       animation: logiAni infinite 5s ease 1s;
//       z-index: 2;
//     }
//   }

//   &.hide {
//     opacity: 0;

//     &.none {
//       display: none;
//     }
//   }
// }
// @keyframes logiAni {
//   0% {
//     transform: skew(-25deg) translate(-150%, -50%);
//   }
//   100% {
//     transform: skew(-25deg) translate(150%, -50%);
//   }
// }

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 22222;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.5s;

  #spill {
    position: absolute;
    width: 600%;
    height: 600%;
  }

  .logo {
    position: relative;
    overflow: hidden;
    img {
      animation: ease logoHide forwards 0.8s 2.5s;
    }
    &::before {
      width: 200%;
      height: 200%;
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: skew(-15deg) translate(-150%, -50%);
      background-color: rgba(#1f415e, 0.5);
      animation: logiAni infinite 4.4s ease 1s;
      z-index: 2;
    }
  }
}
@keyframes logoHide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes reveal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logiAni {
  0% {
    transform: skew(-25deg) translate(-150%, -50%);
  }
  100% {
    transform: skew(-25deg) translate(150%, -50%);
  }
}

/* -----------------------------------------
  =Default css to make the demo more pretty
-------------------------------------------- */

.clear {
  clear: both;
}

/* -----------------------------------------
	=CSS3 Loading animations
  -------------------------------------------- */

/* =Elements style
  ---------------------- */
.load-wrapp {
  float: left;
  width: 200px;
  height: 100px;
  margin: 0 10px 10px 0;
  padding: 20px 20px 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
}

.load-wrapp p {
  padding: 0 0 20px;
  color: white;
}

.load-wrapp:last-child {
  margin-right: 0;
}

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: white;
}

.ring-1 {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  padding: 10px;
  border: 7px dashed white;
  border-radius: 100%;
}

.ring-2 {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  border: 4px solid white;
  border-radius: 100%;
}

.ball-holder {
  position: absolute;
  width: 12px;
  height: 45px;
  left: 17px;
  top: 0px;
}

.ball {
  position: absolute;
  top: -11px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #4282b3;
}

.letter-holder {
  padding: 16px;
}

.letter {
  float: left;
  font-size: 14px;
  color: white;
}

.square {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: white;
}

.spinner {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.bubble-1,
.bubble-2 {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: white;
}

.bubble-2 {
  top: auto;
  bottom: 0;
}

.bar {
  float: left;
  width: 15px;
  height: 6px;
  border-radius: 2px;
  background-color: white;
}

/* =Animate the stuff
  ------------------------ */
.load-1 .line:nth-last-child(1) {
  animation: loadingA 1.5s 1s infinite;
}

.load-1 .line:nth-last-child(2) {
  animation: loadingA 1.5s 0.5s infinite;
}

.load-1 .line:nth-last-child(3) {
  animation: loadingA 1.5s 0s infinite;
}

.load-2 .line:nth-last-child(1) {
  animation: loadingB 1.5s 1s infinite;
}

.load-2 .line:nth-last-child(2) {
  animation: loadingB 1.5s 0.5s infinite;
}

.load-2 .line:nth-last-child(3) {
  animation: loadingB 1.5s 0s infinite;
}

.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}

.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
}

.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}

.load-4 .ring-1 {
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.load-5 .ball-holder {
  animation: loadingE 1.3s linear infinite;
}

.load-6 .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}

.l-1 {
  animation-delay: 0.48s;
}

.l-2 {
  animation-delay: 0.6s;
}

.l-3 {
  animation-delay: 0.72s;
}

.l-4 {
  animation-delay: 0.84s;
}

.l-5 {
  animation-delay: 0.96s;
}

.l-6 {
  animation-delay: 1.08s;
}

.l-7 {
  animation-delay: 1.2s;
}

.l-8 {
  animation-delay: 1.32s;
}

.l-9 {
  animation-delay: 1.44s;
}

.l-10 {
  animation-delay: 1.56s;
}

.load-7 .square {
  animation: loadingG 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.load-8 .line {
  animation: loadingH 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.load-9 .spinner {
  animation: loadingI 2s linear infinite;
}

.load-9 .bubble-1,
.load-9 .bubble-2 {
  animation: bounce 2s ease-in-out infinite;
}

.load-9 .bubble-2 {
  animation-delay: -1s;
}

.load-10 .bar {
  animation: loadingJ 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loadingA {
  0% {
    height: 15px;
  }

  50% {
    height: 35px;
  }

  100% {
    height: 15px;
  }
}

@keyframes loadingB {
  0% {
    width: 15px;
  }

  50% {
    width: 35px;
  }

  100% {
    width: 15px;
  }
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes loadingD {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingE {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loadingG {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(70px, 0) rotate(360deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes loadingH {
  0% {
    width: 15px;
  }

  50% {
    width: 35px;
    padding: 4px;
  }

  100% {
    width: 15px;
  }
}

@keyframes loadingI {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes loadingJ {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(80px, 0);
    background-color: #f5634a;
    width: 25px;
  }
}
.screen-noti {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-main);
  display: none;
  z-index: 1000;
  span {
    color: white;
    display: block;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    text-align: center;
  }
  @media (max-width: 300px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 300px) {
  body:after {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    content: "Vui lòng mở trang ở thiết bị có màn hình lớn hơn để trải nghiệm!!!";
    color: white;
    font-size: 14px;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-main);
  }
}
