.button {
    display: block;
    .txt {
        display: inline-flex;
        padding: 10px 16px;
        border-radius: 5px;
        color: white;
        background-color: var(--color-primary);
        font-size: 16px;
        font-weight: 600;
        transition: all ease 0.5s;
        text-decoration: none;
        align-items: center;
        @media (max-width: 1600px) {
            font-size: 14px;
        }
        @media (max-width: 1023px) {
            font-size: 16px;
        }
        &.size-sm {
            padding: 8px 16px;
        }
        &.color-main {
            background-color: var(--color-main);
            &:hover {
                opacity: .8;
            }
        }
        &.grey {
            background-color: var(--color-secondary);
            &:hover {
                opacity: .8;
            background-color: var(--color-secondary);

            }
        }
        &:hover {
            cursor: pointer;
            background-color: var(--color-main);
          
        }
        &.isArrow{
            padding: 10px 0px 10px 30px ;
          
            &:hover {
                padding: 10px 16px 10px 16px ;
                .arrow {
                    opacity: 1;
                    img {
                        transform: translateX(0%);
                    }
                }
            }
            .arrow {
                display: block;
                opacity: 0;
                width: 20px;
                transition: var(--transition);
                margin-left: 10px;
                img {
                    transform: translateX(200%);
                    transition: var(--transition);
    
                }
            }
        }
        .arrow {
            display: none;
        }
    }
}