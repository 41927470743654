.core-teams {
  .core-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      @media (max-width: 768px) {
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:nth-child(2) {
        .info {
          order: 2;
          @media (max-width: 678px) {
            order: 1;
          }
        }

        .carreer {
          order: 1;
          @media (max-width: 678px) {
            order: 2;
          }
        }
      }

      .bl {
        width: 50%;
        padding: 60px;
        @media (max-width: 1600px) {
          padding: 50px;
        }
        @media (max-width: 678px) {
          width: 100%;
          padding: 20px;
        }
      }

      
      .info {
        .avata {
          display: flex;
          justify-content: center;
          margin-bottom: 40px;
  
          span {
            display: block;
            background-position: center;
            background-size: cover;
            border-radius: 100%;
            width: 250px;
            position: relative;
  
            &::after {
              width: 100%;
              height: 100%;
              display: block;
              content: "";
              position: absolute;
              top: 4px;
              left: 4px;
              background-color: var(--color-primary);
              border-radius: 100%;
              z-index: -1;
            }
          }
        }
        .meta {
          .name {
            font-size: 30px;
            text-align: center;
            font-weight: bold;
            color: var(--color-main);
            @media (max-width: 1600px) {
              font-size: 26px;
            }
          }
  
          .role {
            font-size: 18px;
            text-align: center;
            font-weight: 400;
            color: black;
            @media (max-width: 1600px) {
              font-size: 16px;
            }
          }
        }
      }
      .carreer {
        background-color: var(--color-main);
        min-height: 600px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          min-height: auto;
        }
        ul {
          margin: 0;
          padding: 0;

          li {
            font-size: 16px;
            color: white;
            margin: 0 0 20px 0;
            list-style-type: none;
            &:last-child {
              margin: 0;
            }

          }
        }
      }
    }
  }
}