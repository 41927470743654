.course-detail {
    .wrap {
        display: flex;
        flex-wrap: wrap;
        .upload-content {
            width: calc(100% - 250px);
            padding-right: 20px;
            @media (max-width: 768px) {
                width: 100%;
                padding-right: 0;
            }
        }
        .side-bar {
            width:250px;
            padding:20px;
            background-color: #e1e1e1;
            border-radius: 5px;
            @media (max-width: 768px) {
                width: 100%;
            }
            .calendar {
                width: 100%;
                color: white;
                text-align: center;
                background-color: red;
                border-radius: 4px;
                margin-bottom: 20px;

                .day {
                    font-size: 46px;
                    font-weight: 800;
                    padding: 12px 8px 0px;
                    @media (max-width: 1600px) {
                        font-size: 30px;
                    }
                }
                .month {
                    font-size: 22px;
                    font-weight: 400;
                    padding-bottom: 12px;
                    border-bottom: 1px solid white;
                }
                .year {
                    font-size: 26px;
                    padding: 12px 8px;
                    font-weight: 600;
                }
            }
            .meta {
                .item {
                    margin-bottom: 10px;
                    display: flex;
                  
                    .value {
                        width: calc(100% - 20px);
                        font-weight: bold;
                        color: var(--color-main);
                        text-transform: uppercase;
                        padding-left: 20px;
                    }
                    
                }
            }
            .register {
                .button {
                    a {
                        width: 100%;
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
    }
    
}