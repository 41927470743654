.solution {
    .solution-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .item {
            width: calc(33.33% - 30px);
            margin: 0 15px 40px;

            @media (max-width: 1023px) {
                width: calc(50% - 10px);
                margin: 0 5px 10px;
            }
            @media (max-width: 500px) {
                width: 100%;
                margin: 0 0  20px;
            }
        }
    }

    .titlebar {
        .img {
            width: 40%;
            margin: auto;
            min-width: 250px;
        }
    }

    .badge-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            display: flex;
            align-items: center;
            width: calc(50% - 10px);
            border-radius: 10px;
            transition: all ease 0.5s;
            border: 1px solid #e1e1e1;
            margin: 0 0 20px 0;
            background-color: var(--color-main);
            overflow: hidden;
            @media (max-width: 768px) {
                width: 100%;
            }
            .icon {
                padding: 20px;
                .img {
                    width: 40px;
                    img  {
                        margin-bottom: 0;
                    }
                }
            }
            .meta {
                width: calc(100% - 80px);
                border-left: 1px solid #e1e1e1;
                padding: 20px;
                height: 100%;
                background-color: white;
                .label {
                    font-size: 20px;
                    font-weight: bold;
                    color: var(--color-main);
                    margin-bottom: 10px;
                    @media (max-width: 1600px) {
                        font-size: 18px;
                    }
                    @media (max-width: 1023px) {
                        font-size: 16px;
                    }
                }
                .description {
                    font-size: 14px;
                    
                }
            }
        }
    }
}
