.feature {
    border: 1px solid var(--color-main);
    border-radius: 20px;
    padding: 20px;
    transition: all ease .5s;
    height: 100%;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 20px 0 rgba(#1f415e, .5);

        .feature-meta {
            .title {
                &::before {
                    width: 50%;
                }
            }
        }
    }

    .feature-icon {
        width: 50px;
        margin: 0 auto 20px;
    }

    .feature-meta {
        text-align: center;

        .title {
            font-size: 20px;
            font-weight: bold;
            color: var(--color-main);
            padding-bottom: 16px;
            margin-bottom: 16px;
            position: relative;
            min-height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1600px) {
                font-size: 18px;
            }

            @media (max-width: 1023px) {
                font-size: 16px;
            }

            &::before {
                width: 40px;
                height: 2px;
                display: block;
                content: "";
                transition: all ease .5s;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: var(--color-primary);
            }
        }

        .description {
            font-size: 14px;
            color: var(--color-secondary);
          
        }
    }
}