.filter {
  input,
  select {
    height: 44px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    color: var(--color-main);
    padding: 0 16px;
    box-sizing: border-box;
    transition: all ease 0.5s;
    border-radius: 4px;
    font-weight: 600;
    &:hover,
    &:focus {
      border: 1px solid var(--color-main);
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--color-main);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--color-main);
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--color-main);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--color-main);
    }
  }
  .select-wrap {
    position: relative;
    &::before {
      width: 16px;
      height: 16px;
      display: block;
      content: "";
      background: url("../../assets/images/arrow-down-grey.png") center
        no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      z-index: 2;
    }
    select {
      padding: 0 32px 0 12px;
      appearance: none;
      width: 100%;
      background-color: transparent;
    }
  }

  .top {
    margin-bottom: 20px;
    @media (max-width: 1023px) {
      margin-bottom: 16px;
    }
    .search {
      display: flex;
      justify-content: space-between;
      input {
        width: calc(100% - 170px);
        @media (max-width: 1023px) {
          width: calc(100% - 144px);
        }
      }
      .button {
        display: inline-flex;
        padding: 10px 16px;
        border-radius: 5px;
        color: white;
        background-color: var(--color-primary);
        font-size: 16px;
        font-weight: 600;
        transition: all ease 0.5s;
        text-decoration: none;
        align-items: center;
        width: 150px;
        transition: all ease 0.5s;
        box-sizing: border-box;
        @media (max-width: 1600px) {
          font-size: 14px;
        }
        @media (max-width: 1023px) {
          width: 130px;

        }
        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
        span {
          white-space: nowrap;
          width: calc(100% - 18px);
          padding-right: 12px;
        }
        .icon {
          width: 18px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .item {
      width: calc(33% - 10px);
      @media (max-width: 1023px) {
        width: 100%;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
}
.schedule {
  .list {

    .block {
      margin-bottom: 40PX;
      &:last-child {
        margin-bottom: 0;
      }
      .block-duration {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20PX;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .label {
        width: 100%;
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: bold;
        border-radius: 10px;
        color: var(--color-main);
        &.sub {
          color: black;
          font-size: 16px;
          font-weight: 500;
          display: inline-block;
          span {
            color: var(--color-primary);
            font-weight: bold;
          }
        }
      }
    }
    .item {
      width: calc(50% - 10px);
      margin-bottom: 20px;
      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
  
}
