.news-slider {
    .swiper {
        margin-top: -40px;
        padding-top: 80px;
        padding-bottom: 60px;
        @media (max-width: 1023px){
            width: calc(100% + 20px);
        }
        .swiper-button-next {
            top: 0;
            transform: translateY(0);
            background: url('../../assets/images/5.png') center no-repeat;
            background-size: 100%;
            width: 40px;
            height: 40px;
            right: 0;
            @media (max-width: 1023px) {
                right: 20px;
            }
        }
        .swiper-button-prev {
            top: 0;
            left: inherit;
            right: 50px;
            height: 40px;

            transform: translateY(0);
            background: url('../../assets/images/6.png') center no-repeat;
            background-size: 100%;
            width: 40px;
            @media (max-width: 1023px) {
                right: 70px;
            }

        }
    }
}