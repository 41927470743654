.badge {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &.size-sm {
        .label {
            font-size: 14px;
        }
        .icon {
            width: 20px;
        }
    }
    &.tags {
        align-items: flex-start;
        .label {
            span {
                display: inline-block;
                padding: 4px 8px;
                border-radius: 50px;
                font-size: 12px;
                margin-right: 4px;
                font-weight: 500;
                color: white;
                background-color: red;
                margin: 0 5px 5px 0;
            }
        }
        
    }
    &.size-md {
        .label {
            font-size: 16px;
          
            @media (max-width: 1023px) {
                font-size: 14px;
            }
        }
    }
    &.size-lg {
        .label {
            font-size: 20px;
            @media (max-width: 1023px) {
                font-size: 16px;
            }
        }
    }
    .icon {
        width: 24px;
        img {
            display: block;
            margin: 0;
        }
    }

    .label {
        padding-left: 10px;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;
    }
    
}
