.home {

    .s2,
    .s4,
    .s5 {
        padding: 80px 0;

        @media (max-width: 1023px) {
            padding: 40px 0;
        }
    }

    .s3 {
        padding: 80px 0 120px;
        background: var(--color-main) url('../../assets/images/core-bg.jpg') center no-repeat;
        background-size: cover;

        .core-team {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media (max-width: 768px) {
                padding: 0 20px;
            }
            .item {
                width: 20%;
                margin: 0 60px;
                transition: all ease .5s;
                @media (max-width: 1600px) {
                    width: 25%;
                    margin: 0 40px;
                }
                @media (max-width: 1023px) {
                    width: calc(50% - 40px);
                    margin: 0 20px;
                }
                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
        
                }
                @media (max-width: 700px) {
                    width: 100%;
                    margin: 0 0 40px 0;
                    max-width: 250px;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        @media (max-width: 1023px) {
            padding: 40px 0 80px;
        }
    }
    .s5 {
        position: relative;

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;

            span {
                display: block;
                position: relative;

                &::before {
                    content: "";
                    padding-bottom: 20%;
                    display: block;

                    @media (max-width: 1023px) {
                        padding-bottom: 70%;
                    }
                }
            }
        }

        .content-w {
            position: relative;
            z-index: 2;
        }
    }
    .s1 {
        .badge-list {
            row-gap: 10px;

            .item {
                @media (max-width: 1023px) {
                    width: 100%;
                    margin: 8px 0;
                }
            }
        }
    }
    .s4 {
        .list-services {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media (max-width: 1023px) {
                width: calc(100% + 10px);
                margin-left: -5px;
            }
            .item {
                width: calc(25% - 30px);
                margin: 0 15px 40px;

                @media (max-width: 1023px) {
                    width: calc(50% - 10px);
                    margin: 0 5px 10px;
                }
                @media (max-width: 500px) {
                    width: 100%;
                    margin: 0 0px 20px;
                }
            }
        }

        .badge-list {
            .item {
                @media (max-width: 1023px) {
                    width: 50%;
                    margin: 8px 0;
                }
            }
        }
    }
    .customer {
        padding: 80px 0 120px;
        background: var(--color-main) url('../../assets/images/core-bg.jpg') center no-repeat;
        background-size: cover;
        .list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 768px) {
                justify-content: center;
            }
            .item {
                width: calc(20% - 20px);
                transition: all ease .5s;
                position: relative;
                overflow: hidden;
                border-radius: 20px;

                @media (max-width: 1023px) {
                    display: flex;
                    align-items: center;
                }
                .logo  {
                    background-color: white;
                    padding: 20px;
                    border-radius: 20px;
                    width: 100%;
                    overflow: hidden;
                }
                &:hover {
                    cursor: pointer;
                    .overlay {
                        opacity: 1;
                        span {
                            transform:scale(1);
                        }
                    }
                }
                .overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    padding: 20px;
                    font-size: 22px;
                    text-align: center;
                    display: flex;
                    border-radius: 20px;
                    align-items: center;
                    justify-content: center;   
                    background-color: rgba(#9aca3c, 1);     
                    color: var(--color-main);
                    text-transform: uppercase;
                    font-family: 'Gentium Book Plus';
                    font-weight: 900;
                    opacity: 0;     
                    transition: all ease .5s;    
                    line-height: 1.2;
                    @media (max-width: 1600px) {
                        font-size: 20px;
                    }
                    @media (max-width: 1023px) {
                        font-size: 18px;
                        position: static;
                        background-color: transparent;
                        opacity: 1;
                        color: white;
                        text-align: left;
                        
                    }
                    span {
                        transform: scale(.8);
                        transition: all ease .5s;    
                        @media (max-width: 1023px) {
                            transform: scale(1);
                        }
                    }
                }
                @media (max-width: 1600px ) {
                    width: calc(20% - 20px);
                }
                @media (max-width: 1023px ) {
                    width: calc(20% - 10px);
                }
                @media (max-width: 768px ) {
                    width: 100%;
                    margin: 0 0px 20px;;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
              
            }
        }
    }
    .core-partner {
        background:url('../../assets/images/Picture4.png') center no-repeat;
        background-size:contain;
        padding: 60px 0 80px;
        @media (max-width: 1023px) {
            padding: 40px 0;
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item {
                width: 40%;
                @media (max-width: 1023px) {
                    width: 100%;
                }
                &.main {
                    padding: 80px 5vw;
                    border: 2px solid var(--color-main);
                    border-radius: 20px;
                    background-color: white;
                    position: relative;
                    overflow: hidden;
                    @media (max-width: 1440px) {
                        padding: 60px 30px;
                    }
                    @media (max-width: 1023px) {
                        padding: 20px 20px;

                    }
                    &:hover {
                        cursor: pointer;
                        .info {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                    .info {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--color-main);
                        opacity: 0;
                        transition: all ease .7s;
                        @media (max-width: 1023px) {
                            position: static;
                            opacity: 1;
                            height: auto;
                            margin-top: 20px;
                            border-radius: 15px;
                        }
                        &.p1 {
                            .wrap {
                                display: flex;
                                flex-wrap: wrap;
                                height: 100%;
                                .info-item {
                                    width: 50%;
                                    height: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 20px;
                                    border-bottom: 1px dashed white;
                                    border-right: 1px dashed white;
                                    @media (max-width: 1023px) {
                                        width: 100%;
                                        height: auto;
                                    }
                                    &:nth-child(2) {
                                        border-right: none;
                                    } 
                                    &:nth-child(3) {
                                        border-bottom: none;
                                        @media (max-width: 1023px) {
                                            border-bottom: 1px dashed white;

                                         }
                                    } 
                                    &:nth-child(4) {
                                        border-right: none;
                                        border-bottom: none;
                                        @media (max-width: 1023px) {
                                           border-bottom: inherit;
                                        }
                                    } 
                                    .no {
                                        font-size: 50px;
                                        text-align: center;
                                        color: var(--color-primary);
                                        font-weight: bold;
                                        @media (max-width: 1600px) {
                                            font-size: 40px;
                                        }
                                    }
                                    .value {
                                        &.uppercasetext {
                                            text-transform: uppercase;
                                        }
                                        font-size: 18px;
                                        text-align: center;
                                        color: white;
                                        min-height: 55px;
                                        @media (max-width: 1600px) {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                        &.p2 {
                            padding: 30px;
                            display: flex;
                            align-items: center;
                            @media (max-width: 1023px) {
                                padding: 20px;
                            }
                            .wrap {
                                .info-item {
                                    width: 100%;
                                    margin-bottom: 20px;
                                }
                                .title {
                                    font-size: 20px;
                                    font-weight: bold;
                                    color: white    ;
                                    color: var(--color-primary);
                                    margin-bottom: 10px;
                                    text-align: center;
                                    text-transform: uppercase;
                                    @media (max-width: 1600px) {
                                        font-size: 18px;
                                    }
                                }
                                .value {
                                    font-size: 16px;
                                    text-align: center;
                                    color: white;
                                    @media (max-width: 1600px) {
                                        font-size: 14px;
                                    }
                                    
                                }
                            }
                        }
                    }
                   
                }
                .logo {
                    width: 70%;
                    max-width: 400px;
                    margin: 0 auto 20px;    
                    @media (max-width: 1023px) {
                        width: 100%;
                    }
                }
                &:nth-child(2) {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    @media (max-width: 1023px) {
                        width: 100%;
                        margin: 20px 0;
                    }
                    .icon {
                        width: 70%;
                        margin: auto;
                        position: relative;
                        @media (max-width: 1023px) {
                            width: 50px;
                           
                        }
                        .img {
                            position: relative;
                            &::before   {
                                width: 100px;
                                height: 100px;
                                display: block;
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                background-color: rgba(#1f415e, .02);
                                border-radius: 100%;
                                z-index: -1;
                                animation: spread infinite ease-in-out 5s .8s;
                            }
                            &::after {
                                width: 100px;
                                height: 100px;
                                display: block;
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                background-color: rgba(#1f415e, .02);
                                border-radius: 100%;
                                z-index: -1;
                                animation: spread infinite ease-in-out 5s .6s;
                            }
                        }
                        &::before   {
                            width: 100px;
                            height: 100px;
                            display: block;
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            background-color: rgba(#1f415e, .02);
                            border-radius: 100%;
                            z-index: -1;
                            animation: spread infinite ease-in-out 5s ;
                        }
                        &::after {
                            width: 100px;
                            height: 100px;
                            display: block;
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            background-color: rgba(#1f415e, .02);
                            border-radius: 100%;
                            z-index: -1;
                            animation: spread infinite ease-in-out 5s .3s;
                        }
                       
                    }
                }
                .description {
                    font-size: 18px;
                    color: var(--color-main);
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 20px;
                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }
                   
                }
                .prize {
                    width: 70%;
                    max-width: 400px;
                    margin: auto;
                }
            }
        }
    }
}

@keyframes spread {
    0% {
        transform: translate(-50%,-50%) scale(0);
        opacity: 0;
    }
    50% {

        opacity: 1;
    }
    100% {
        transform: translate(-50%,-50%) scale(7);
        opacity: 0;
    }
}