.page404 {
    .wrap {
        .text {
            font-size: 22px;
            text-align: center;
            color: var(--color-main);
            font-weight: 500;
        }

        .action {
            text-align: center;

            a {
                width: 100%;
                padding: 10px 16px;
                border-radius: 5px;
                color: white;
                background-color: var(--color-primary);
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                border: 0;
                text-decoration: none;
                transition: all ease 0.5s;

                &:hover {
                    background-color: var(--color-main);

                }
            }
        }
    }
}