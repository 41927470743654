.list-partner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
        width: calc(33% - 30px);
        margin-bottom: 60px;
        padding: 40px;
        border-radius: 10px;
        background-color: #F3F8FD;
        position: relative;
        @media (max-width: 1023px) {
            width: 100%;
            margin-bottom: 40px;
        }
        .item-wrap {
            height: 100%;
            border-bottom: 2px solid var(--color-primary);
            padding-bottom: 40px;
        }
        .partner-logo {
            .img {
                img {
                    margin-bottom: 0;
                }
            }
        }
        .description {
            font-size: 16px;
            color: black;
            font-weight:400;
        }
    }
}