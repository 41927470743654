.card {
  &:hover {
    cursor: pointer;

    .card-wrap {
      .card-thumb {
        span {
          transform: scale(1.1);

        }
      }

      .card-meta {
        transform: translateY(-35px);
        border: 1px solid var(--color-main);
      }

      .line-bottom {
        span {
          width: 100%;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  &-wrap {
    .card-thumb {
      overflow: hidden;
      border-radius: 0 0 4px 4px;

      span {
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        transition: all ease 2s;
      }
    }

    .card-meta {
      background-color: white;
      padding: 28px 30px;
      border-radius: 4px;
      width: calc(100% - 40px);
      margin: 0 auto;
      transform: translateY(-28px);
      position: relative;
      transition: all ease 0.5s;
      z-index: 2;
      border: 1px solid transparent;

      @media (max-width: 1023px) {
        padding: 20px 24px;

      }

      .date {
        display: flex;
        margin-bottom: 24px;

        .icon {
          width: 16px;
          margin-right: 10px;
        }

        .time {
          font-size: 12px;
          color: var(--color-secondary);
        }
      }

      .title {
        font-size: 18px;
        color: var(--color-main);
        font-weight: 600;
        margin-bottom: 20px;
        transition: all ease 0.5s;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 55px;
      }

      .excerpt {
        margin-bottom: 20px;

        font-size: 14px;
        font-weight: 400;
        color: var(--color-main);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 37px;
      }

      .action {
        span {
          font-size: 14px;
          font-weight: 600;
          color: var(--color-main);
          position: relative;
          padding-right: 30px;
          transition: all ease 0.5s;

          &::before {
            width: 16px;
            height: 20px;
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            background: url("../../assets/images/arrow-right.png") center no-repeat;
            background-size: 100%;
          }
        }
      }
    }

    .line-bottom {
      height: 3px;
      position: relative;
      background-color: var(--color-primary);
      width: 100%;

      span {
        width: 00%;
        height: 100%;
        position: absolute;
        top: 00;
        left: 0;
        background-color: var(--color-main);
        transition: all ease 0.5s;
      }
    }
  }
}