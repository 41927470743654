.banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .inner {
        position: absolute;
        top: 0%;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }
 
    .content-w {
    }
    .block-title {
        position: relative;
        z-index: 2;
    }
}