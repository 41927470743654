.about-us {
    .badge-list {
        .item {
            @media (max-width: 1023px) {
                margin: 16px 10px;
            }
        }
    }
    .list-services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .item {
            width: 25%;
            margin: 0 0 40px;
            @media (max-width: 1023px) {
                width: 50%;
            }
        }
    }
}