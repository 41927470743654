.register-course {
  form {
    .form-groups {
      .form-groups-w {
        position: relative;
        .icon {
          position: absolute;
          width: 20px;
          left: 14px;
          top: 22px;
          transform: translateY(-50%);
        }
        input {
          padding: 0 14px 0 40px;
        }
      }
    }
  }
}
