.schedule-item {
    // box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 10px;
    transition: all ease .5s;
    border: 1px solid #e1e1e1;
    &:hover {
        box-shadow: 0px 0px 20px 0 rgba(#1f415e, 0.3);
        .title {
            color: var(--color-primary) !important;
        }
    }
    .wrap {
        display: flex;
        flex-wrap: wrap;
    .calendar {
            width: 120px;
            color: white;
            text-align: center;
            background-color: red;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .c-wrap {
                width: 100%;
            }
          
            .day {
                font-size: 40px;
                font-weight: 800;
                @media (max-width: 1600px) {
                    font-size: 34px;
                }
            }
            .month {
                font-size: 18px;
                font-weight: 400;
                padding-bottom: 12px;
                border-bottom: 1px solid white;
                @media (max-width: 1600px) {
                    font-size: 16px;
                }
            }
            .year {
                font-size: 30px;
                padding: 12px 8px;
                font-weight: 600;
                @media (max-width: 1600px) {
                    font-size: 24px;
                }
            }
        }
        .meta {
            width: calc(100% - 120px);
            padding-left: 20px;

            @media (max-width: 1023px) {
                padding-left: 16px;
            }
            .meta-wrap {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
            .column {
                &:nth-child(1) {
                    width: 70%;
                    @media (max-width: 1023px) {
                        width: 100%;
                    }
                }
                &:nth-child(2) {
                    width: 30%;
                    text-align: right;
                    @media (max-width: 768px) {
                    width: 100%;
                    }
                    .location {
                        font-size: 14px;
                        color: #808080;
                        text-transform: uppercase;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-bottom: 10px;
                        @media (max-width: 1600px) {
                            font-size: 13px;
                        }
                        @media (max-width: 1023px) {
                            justify-content: flex-start;
                            margin-top: 10px;

                        }
                        .icon {
                            width: 20px;
                            margin-right: 12px;
                        }
                    }
                    .timer {
                        color: #808080;
                        margin-bottom: 10px;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        
                        .icon {
                            width: 20px;
                            margin-right: 12px;
                        }
                        @media (max-width: 1600px) {
                            font-size: 13px;
                        }
                        @media (max-width: 1023px) {
                            font-size: 13px;
                            justify-content: flex-start;
                        }
                        span {
                            color: var(--color-main);
                            font-weight: bold;
                            font-size: 14px;
                            @media (max-width: 1600px) {
                                font-size: 15px;
                            }
                        }
                    }
                    .method {
                        font-size: 14px;
                        @media (max-width: 1600px) {
                            font-size: 13px;
                        }
                        @media (max-width: 1023px) {
                           text-align: left;
                        }
                        span {
                            color: var(--color-primary);
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            .title {
                pointer-events: none;
                cursor: default;
                font-size: 18px;
                color: var(--color-main);
                font-weight: 600;
                margin-bottom: 0px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-decoration: none;
                transition: all ease 0.5s;
                text-transform: uppercase;
                margin-bottom: 6px;
                min-height: 55px;
                @media (max-width: 1600px) {
                    font-size: 16px;
                }
                @media (max-width: 1023px) {
                    -webkit-line-clamp: 3;
                    min-height: auto;
                }
            }
            .category {
                font-size: 14px;
                color: #808080;
                text-transform: uppercase;
                margin-bottom: 6px;
                min-height: 21px;

                @media (max-width: 1600px) {
                    font-size: 13px;
                }
            }
           
            .tags {
                min-height: 25px;
                span {
                    display: inline-block;
                    padding: 2px 8px;
                    border-radius: 50px;
                    font-size: 12px;
                    margin-right: 4px;
                    font-weight: 500;
                    color: white;
                    background-color: red;
                }
            }
        }
        .action {
            width: 100%;
            display: flex;
            margin-top: 60px;
            flex-wrap: wrap;
            @media (max-width: 1023px) {
                width: 100%;
                margin-top: 40px;
            }
            .download {
                &.disabled {
                    .icon{
                        display: none;
                    }
                    .txt {
                        color: #808080;
                    }
                    pointer-events: none;
                    cursor: default;
                }
                width: 50%;
                display: flex;
                align-items: center;
                transition: all ease 0.5s;
                text-decoration: none;
                @media (max-width: 1023px) {
                    justify-content: flex-start;
                }
                @media (max-width: 1023px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                }
                .icon {
                    width: 20px;
                }
                .txt {
                    font-size: 16px;
                    font-weight: 600;
                    padding-left: 12px;
                    color: var(--color-main);
                    @media (max-width: 1600px) {
                        font-size: 14px;
                    }
                }
            }
            .register {
                width: 50%;
                display: flex;
                justify-content: flex-end;
                @media (max-width: 1023px) {
                    width: 100%;
                    .button {
                        display: block;
                        width: 100%;
                        a{
                            display: block;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
