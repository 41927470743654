.banner-slider {
    min-height: 70vh;
    &.active {
        opacity: 1;

        .slide {
            .slide-content {
            }
        }
    }

    .slide {
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        position: relative;
        @media (max-width: 1023px) {
            min-height: 500px;
        }
        &-content {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40%;
            transform: translate(0px, -50%);


            @media (max-width:1600px) {
                width: 50%;
                left: 45%;
            }

            @media (max-width:1023px) {
                width: 100%;
                left: 20px;
            }

            label {
                font-size: 16px;
                color: var(--color-main);
                opacity: 0.5;
                font-weight: 600;
                display: block;
           
            }

         
            h4 {
                margin: 0 0 30px 0;
                font-size: 35px;
                color: var(--color-main);
                font-weight: 600;
                @media (max-width: 1600px) {
                    font-size: 32px;
                }
                @media (max-width: 1023px) {
                    font-size: 20px;
                    margin: 0 0 20px 0;
                }
            }
            .button {
            }
        }
    }
}