.ReactModal__Overlay {
    z-index: 1050;
    background: rgba(31, 65, 94, 0.9) !important;
    overflow-y: auto;

    .ReactModal__Content {
        inset: 0 !important;
        width: calc(100% - 40px);
        margin: 60px auto;
        height: max-content;
        max-width: 500px;
        background-color: white;
        border-radius: 10px;
        padding: 40px 16px;
        position: relative;
        outline: none;

        &.bg-transparent {
            background-color: transparent;
        }

        &.guide {
            padding: 0;

            &::before,
            &::after {
                display: none;
            }

            .modal-content {
                &::before {
                    display: none;
                }
            }
        }

        .modal-close {
            width: 50px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .modal-content {
            position: relative;
            width: 100%;

            .meta{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
            }

            .modal-title {
                font-size: 20px;
                font-weight: 900;
                text-align: center;
                margin-bottom: 24px;
                color: var(--color-main);
                text-transform: uppercase;

                &.size-sm {
                    font-size: 16px;
                }
            }

            .text {
                &.note {
                    p {
                        font-size: 12px;
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;

                    span {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
            }

            .dates {
                font-size: 20px;
                font-weight: 900;
                text-align: center;
            }

        }

        .block-action {
            width: 100%;
            top: 100%;
            left: 0;
            z-index: 2;
            display: flex;
            justify-content: space-between;

            .item {
                width: calc(50% - 10px);

            }
            .one-item {
                width: 100%;
            }

            .button {
                width: 100%;

                .txt {
                    font-size: 16px;
                    height: 42px;
                    padding: 2px 24px 0;
                    font-weight: bold;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    line-height: 1;
                    transition: all ease 0.5s;
                    min-width: 150px;
                    width: 100%;

                    @media (max-width: 1600px) {
                        font-size: 14px;
                    }

                    &:hover,
                    &:focus {
                        opacity: 0.8;
                    }
                }

                &.button1 {
                    span {
                        color: #FFFD59;
                        border: 2px solid var(--color-main);
                        background-color: var(--color-main);

                    }
                }

                &.button2 {
                    span {
                        color: white;
                        border: 2px solid white;
                        background-color: #1D5AA1;
                    }
                }

                &.toZLP {
                    a {
                        text-decoration: none;
                        text-transform: capitalize;
                        color: white;
                        background-color: #41B874;
                        font-weight: bold;

                        strong {
                            padding: 0 0 0 4px;
                            display: inline-flex;
                            color: var(--color-base);
                        }
                    }
                }
            }
        }
    }
}