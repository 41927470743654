.menu {
    nav {
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            li {
                list-style-type: none;
                margin: 0 10px;

                @media (max-width: 1280px) {
                    margin: 0 5px;
                }

                @media (max-width: 1023px) {
                    width: 100%;
                    margin: 0;
                    padding: 0 20px;
                }

                &.has-dropdown {
                    position: relative;

                    > a {
                        position: relative;
                        padding: 10px 40px 10px 10px;
                        display: inline-block;
                        @media (max-width: 1600px) {
                            padding: 10px 30px 10px 5px;
                        }
                        @media (max-width: 1280px) {
                            padding: 10px 20px 10px 5px;
                        }
                        @media (max-width: 1170px) {
                            padding: 10px 15px 10px 5px;
                        }
                        @media (max-width: 1023px) {
                            padding: 10px 0;
                        }
                        &::before {
                            display: block;
                            content: "";
                            position: absolute;
                            top: 50%;
                            right: 12px;
                            transform: translateY(-50%);
                            width: 16px;
                            height: 16px;
                            background: url("../../assets/images/arrow-down.png") center no-repeat;
                            background-size: 100%;
                            @media (max-width: 1600px) {
                                right: 5px;
                            }
                            @media (max-width: 1280px) {
                                right: 0;
                            }
                        }
                    }

                    .dropdown {
                        position: absolute;
                        width: auto;
                        top: 100%;
                        left: 0;
                        display: none;
                        background-color: white;
                        overflow: hidden;
                        z-index: 2;
                        min-width: 400px;
                        transition: all ease 0.5s;
                        border: 1px solid #e1e1e1;
                        @media (max-width: 1023px) {
                            position: static;
                            width: 100%;
                            max-width: 100%;
                            min-width: auto;
                            &.active {
                                display: block;
                            }
                        }

                        ul {
                            flex-wrap: wrap;
                        }

                        li {
                            font-weight: 400;
                            width: 100%;
                            margin: 0;
                            border-bottom: 1px solid #e1e1e1;
                            @media (max-width: 1023px) {
                                padding: 0;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                            &:hover {
                                a {
                                    .main {
                                        color: var(--color-primary);
                                        &::before {
                                            transform: translate(10px, -50%);
                                        }
                                    }
                                }
                            }
                            a {
                                padding: 24px 20px;
                                display: block;
                                &.active {
                                    background: #e1e1e1;
                                }
                                .highlight {
                                    color: var(--color-primary);
                                }
                                .main {
                                    width: 100%;
                                    transition: all ease 0.5s;
                                    white-space: nowrap;
                                    color: var(--color-main);
                                    font-weight: 600;
                                    padding: 0;
                                    max-width: 500px;
                                    position: relative;
                                    padding-right: 24px;
                                    transition: all ease 0.5s;
                                    @media (max-width: 1023px) {
                                        white-space: normal;
                                        display: block;
                                        max-width: auto;
                                        width: 100%;
                                    }
                                    p {
                                        margin: 0;
                                        display: inline-block;
                                    }
                                    &::before {
                                        width: 16px;
                                        height: 20px;
                                        display: block;
                                        content: "";
                                        background: url("../../assets/images/arrow-right.png") center no-repeat;
                                        background-size: 100%;
                                        position: absolute;
                                        top: 50%;
                                        right: 0;
                                        transform: translate(0, -50%);
                                        transition: all ease 0.5s;
                                    }
                                }
                                .description {
                                    color: black;
                                    opacity: 0.6;
                                    font-size: 12px;
                                    font-weight: 400px;
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                    @media (min-width: 1024px) {
                        &:hover, &.focus {
                            .dropdown {
                                display: block;
                            }
                        }
                    }
                  
                }

                a {
                    color: white;
                    font-size: 14px;
                    font-weight: 500;
                    color: white;
                    padding: 10px;
                    text-decoration: none;
                    transition: all ease 0.5s;
                    text-align: left;

                    @media (max-width: 1700px) {
                        padding: 10px 5px;
                    }
                    @media (max-width: 1550px) {
                        font-size: 13px;
                    }
                    @media (max-width: 1360px) {
                        font-size: 12px;
                    }
                    @media (max-width: 1170px) {
                        font-size: 11px;
                       
                    }

                    @media (max-width: 1023px) {
                        font-size: 16px;
                        width: 100%;
                        display: block;
                        padding: 10px 0;
                    }
                }

                .lv1 {
                    position: relative;
                    text-transform: uppercase;
                    &:hover,
                    &:focus,
                    &.active {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}
